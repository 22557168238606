
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { useShortenedLink } from "@/shared/composables/useShortenedLink";
import routeNames from "@/shared/router/routeNames";
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const { resolveShortenedLink } = useShortenedLink();

    onMounted(async () => {
      console.group("ShortenedLinkRedirect onMounted");

      const currentUrl = window.location.origin + window.location.pathname;
      const fullUrl = await resolveShortenedLink({ url: currentUrl });

      if (!fullUrl) {
        console.log(`Invalid Short Link: ${currentUrl}`);

        router.replace({ name: routeNames.home });
      } else {
        const url = new URL(fullUrl);

        // If INTERNAL URL - same as FE
        if (url.origin === window.location.origin) {
          const fullPath = url.pathname + url.search + url.hash;
          const resolvedRoute = router.resolve(fullPath);

          console.log(`fullPath: ${fullPath}`);
          console.log(`resolvedRoute:`);
          console.log(resolvedRoute);

          // If vue-router recognizes route
          if (
            resolvedRoute.matched.length &&
            resolvedRoute.matched.at(0)?.name !== routeNames.shortLinkRedirect
          ) {
            console.log(`[Internal] Valid Short Link: ${currentUrl}`);

            router.replace(resolvedRoute);
          }
          // Else, fallback to home
          else {
            console.log(`[Internal] Invalid Short Link: ${currentUrl}`);

            router.replace({ name: routeNames.home });
          }
        }
        // Else if EXTERNAL
        else {
          console.log(`[External] Valid Short Link: ${currentUrl}`);

          window.location.replace(fullUrl);
        }
      }

      console.groupEnd();
    });
  },
  components: { PageLayout },
});
